import { useNavigate } from "react-router-dom";
import "./Products.css";
import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from "react";
import { MenuItem } from "@mui/material";
import LoadingOverlay from "../../Shared/Components/Overlays/LoadingOverlay/LoadingOverlay";
import ErrorDialog from "../../Shared/Components/Dialogs/ErrorDialog/ErrorDialog";
import BasicButton from "../../Shared/Components/Buttons/BasicButton";
import { buttonSizes, buttonVariants } from "../../Shared/Components/Buttons/ButtonEnums";
import SimpleDropdown from "../../Shared/Components/Dropdowns/SimpleDropdown";
import productServiceInstance from "../../Shared/Services/product-service";
import userContextServiceInstance from "../../Shared/Services/user-context-service";

const Products = () => {
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const navigate = useNavigate();
    const containerStyleClasses = isMobile ? "landing-page-container-small" : "landing-page-container-large";
    const contentStyleClasses = isMobile ? "landing-page-content-small" : "landing-page-content-large";
    const [selectedProduct, setSelectedProduct] = useState("");
    const [selectedProductID, setSelectedProductID] = useState("");
    const [products, setProducts] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const getProducts = async () => {
            var productsResponse = await productServiceInstance.getProducts();
            if(productsResponse.status !== 200){
                setError(true);
                setShowLoading(false);
                return;
            }

            setProducts(productsResponse.data);
            setShowLoading(false);
        }

        setShowLoading(true);
        getProducts().catch(err => {
            console.error(err);
            setShowLoading(false);
            setError(true);
        });
    }, []);

    const getDropDownComponent = () => {
        let items = [];

        products.forEach(product => {
            items.push(<MenuItem key={product.productEntityID} value={product.name}>{product.name}</MenuItem>); 
        });

        return (
            <SimpleDropdown label="Product name" defaultValue={selectedProduct} handleChange={handleDropdownChange} 
                size="small">
                {items}
            </SimpleDropdown>
        );
    }

    const handleDropdownChange = (event) => {
        var productId = "";
        products.forEach(product => {
            if(product.name === event.target.value){
                productId = product.productEntityID;
            }
        });

        setSelectedProduct(event.target.value);
        setSelectedProductID(productId);
    }

    const SelectProductClicked = () => {
        userContextServiceInstance.setProductEntityID(selectedProductID);
        userContextServiceInstance.setProductName(selectedProduct);
        navigate("/product-manager");
    }

    return (
        <div className={containerStyleClasses}>
            <div className={contentStyleClasses}>
                Select your product
                {getDropDownComponent()}
                <BasicButton variant={buttonVariants.contained} size={buttonSizes.medium} text="Launch Product Admin" 
                        onClick={SelectProductClicked} color="primary" disabled={selectedProductID === ""}/>
            </div>
            {showLoading && <LoadingOverlay />}
            <ErrorDialog open={error} handleClose={setError} title="Error">
                An unexpected error occured. Please try again later.
            </ErrorDialog>
        </div>
    );
}

export default Products;
