import "./ProductManager.css";
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Users from "..//Users/Users";
import Businesses from "../Businesses/Businesses";
import Subscriptions from "../Subscriptions/Subscriptions";
import BusinessSubscriptions from "../BusinessSubscriptions/BusinessSubscriptions";

const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ProductManager = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className="product-manager-container">
             <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Users" {...a11yProps(0)} />
                        <Tab label="Businesses" {...a11yProps(1)} />
                        <Tab label="Product Subscriptions" {...a11yProps(2)} />
                        <Tab label="Business Subscriptions" {...a11yProps(3)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <Users />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <Businesses />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <Subscriptions />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    <BusinessSubscriptions />
                </CustomTabPanel>
            </Box>
        </div>
    )
}

export default ProductManager;