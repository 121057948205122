import { useEffect, useState } from "react";
import "./BusinessSubscriptions.css";
import userContextServiceInstance from "../../Shared/Services/user-context-service";
import subscriptionServiceInstance from "../../Shared/Services/subscription-service";
import { v4 as uuidv4 } from 'uuid';
import businessSubscriptionInstance from "../../Shared/Services/business-subscription-service";
import { convertDateObjectToString, convertDateStringToDateObject, formatCurrentDateObjectToString } from "../../Shared/Utils/date-utils";
import DataGrid from "../../Shared/Components/DataGrid/DataGrid";
import LoadingOverlay from "../../Shared/Components/Overlays/LoadingOverlay/LoadingOverlay";
import ErrorDialog from "../../Shared/Components/Dialogs/ErrorDialog/ErrorDialog";
import businessServiceInstance from "../../Shared/Services/business-service";

const BusinessSubscriptions = () => {
    const [showLoading, setShowLoading] = useState(true);
    const [error, setError] = useState(false);
    const [rows, setRows] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [businesses, setBusinesses] = useState([]);

    useEffect(() => {
        const getData = async () => {
            var productEntityId = userContextServiceInstance.getProductEntityID();
            var response = await subscriptionServiceInstance.getAllSubscriptions(productEntityId);
            if(response.status !== 200){
                setError(true);
                setShowLoading(false);
                return;
            }

            var businessResponse = await businessServiceInstance.getAll();
            if(businessResponse.status !== 200){
                setError(true);
                setShowLoading(false);
                return;
            }

            setSubscriptions(response.data);
            setBusinesses(businessResponse.data);

            const gridHeaders = [
                {id: 1, value: "BusinessName", displayValue: "Business Name", cellWidth: 250, validCellValue: validBusinessName, isEditable: false, isDropdownCell: true},
                {id: 2, value: "SubscriptionDescription", displayValue: "Subscription Description", cellWidth: 250, validCellValue: validSubscription, isEditable: true, isDropdownCell: true}, 
                {id: 3, value: "SubcriptionDurationInMonths", displayValue: "Duration (Months)", cellWidth: 150, validCellValue: null, isEditable: false},
                {id: 4, value: "SubscriptionPrice", displayValue: "Price (R)", cellWidth: 150, validCellValue: null, isEditable: false},
                {id: 5, value: "SubscriptionStart", displayValue: "Subscription Start", cellWidth: 150, validCellValue: null, isEditable: false},
                {id: 6, value: "SubscriptionEnd", displayValue: "Subscription End", cellWidth: 150, validCellValue: null, isEditable: false},
                {id: 7, value: "IsActive", displayValue: "Is Active", cellWidth: 100, validCellValue: validIsActive, isEditable: true},
            ];

            setHeaders(gridHeaders);
            
            await getGridRowDataFromApi();
            setShowLoading(false);
        }

        getData().catch(err => {
            console.error(err);
            setShowLoading(false);
            setError(true);
        });
    }, []);

    const getDropdownCellOptions = (headerValue, row) => {
        var options = [];
        switch(headerValue){
            case "SubscriptionDescription":
                subscriptions.forEach(item => {
                    options.push({id: item.entityID, value: item.description, text: item.description});
                });

                return options;
            case "BusinessName":
                businesses.forEach(item => {
                    options.push({id: item.businessEntityId, value: item.name, text: item.name});
                });

                return options;
            default:
                return [];
        }
    }

    const getGridRowDataFromApi = async () => {
        var productEntityId = userContextServiceInstance.getProductEntityID();
        var response = await businessSubscriptionInstance.getAll(productEntityId);
        if(response.status !== 200){
            setError(true);
            setShowLoading(false);
            return;
        }

        let items = response.data;

        let gridRows = [];
        items.forEach(item => {
            let startDate = convertDateObjectToString(item.subscriptionStart);
            let endDate = convertDateObjectToString(item.subscriptionEnd);

            gridRows.push(
                {
                    id: item.businessSubscriptionEntityID, 
                    rowData: { BusinessEntityID: item.businessEntityID, SubscriptionEntityID: item.subscriptionEntityID, SubcriptionDurationInMonths: item.subcriptionDurationInMonths, 
                        SubscriptionDescription: item.subscriptionDescription, BusinessName: item.businessName, SubscriptionEnd: endDate, SubscriptionStart: startDate, 
                        SubscriptionPrice: item.subscriptionPrice, IsActive: item.isActive }, 
                    hasError: false,
                    rowSelected: false,
                    isEmpty: false,
                    hasChanges: false,
                    isComplete: true,
                    isNewRow: false
                }
            );
        })

        gridRows = sortGridRows(gridRows);
        setRows(gridRows);
    }

    const sortGridRows = (rows) => {
        rows.sort((a, b) => {
            let name1 = a.rowData["BusinessName"];
            let name2 = b.rowData["BusinessName"];
            if(name1 === "" || name2 === "") { return 1; }
            return name1.localeCompare(name2);
        });

        return rows;
    }

    const getGridRowCellValue = (headerValue, row) => {
        if(headerValue === "SubcriptionDurationInMonths" || headerValue === "SubscriptionPrice" || headerValue === "SubscriptionEnd"){
            if(row.rowData["SubscriptionDescription"] === ""){
                return "";
            }

            if(row.rowData[headerValue] !== ""){
                return row.rowData[headerValue];
            }
        }

        switch(headerValue){
            default:
                return row.rowData[headerValue];
        }
    }

    const assignRowValues = (row, header, newValue) => {
        row.rowData[header.value] = newValue;
        if(header.value !== "SubscriptionStart" && row.rowData["SubscriptionStart"] === ""){
            row.rowData["SubscriptionStart"] = formatCurrentDateObjectToString();
        }

        if(header.value === "SubscriptionDescription"){
            subscriptions.forEach(item => {
                if(item.description === newValue){
                    row.rowData["SubscriptionEntityID"] = item.entityID;
                    row.rowData["SubcriptionDurationInMonths"] = item.monthlyDuration;
                    row.rowData["SubscriptionPrice"] = item.price;
                    row.rowData["IsActive"] = true;
                    
                    var startDate = convertDateStringToDateObject(row.rowData["SubscriptionStart"]);
                    var endDate = new Date(startDate.setMonth(startDate.getMonth() + item.monthlyDuration));
                    row.rowData["SubscriptionEnd"] = convertDateObjectToString(endDate);
                }
            });
        }

        return row;
    }

    const getEmptyRow = () => {
        return { 
            id: uuidv4(), 
            rowData: { BusinessEntityID: "", SubscriptionEntityID: "", SubcriptionDurationInMonths: "", 
                        SubscriptionDescription: "", BusinessName: "", SubscriptionEnd: "", SubscriptionStart: "", 
                        SubscriptionPrice: "", IsActive: "" }, 
            hasError: false, 
            rowSelected: false, 
            isEmpty: true,
            hasChanges: false,
            isComplete: true,
            isNewRow: true
        };
    }

    const canCommitRow = (row) => {
        return row.rowData["BusinessName"] !== "" && row.rowData["SubscriptionDescription"] !== "" && row.rowData["SubcriptionDurationInMonths"] !== "" && 
            row.rowData["IsActive"] !== "" && row.rowData["SubscriptionPrice"] !== "" && row.rowData["SubscriptionStart"] !== "" && row.rowData["SubscriptionEnd"] !== "";
    }

    const validBusinessName = (value) => {
        return value !== "" && value !== "blank" && value !== null && value !== undefined;
    }

    const validSubscription = (value) => {
        return value !== "" && value !== "blank" && value !== null && value !== undefined;
    }

    const validIsActive = (value) => {
        return value !== null && value !== undefined && (value === "true" || value === "false"); 
    }

    const saveChanges = async (row) => {
        const contextToken = userContextServiceInstance.getContextToken();

        setShowLoading(true);
        if(row.isNewRow){
            let payload = {
                EntityId: row.id,
                BusinessName: row.rowData["BusinessName"],
                SubscriptionEntityID: row.rowData["SubscriptionEntityID"],
                ContextToken: contextToken
            };

            businessSubscriptionInstance.addItemAdmin(payload).then((response) => {
                if(response.status !== 200){
                    setError(true);
                    setShowLoading(false);
                    return;
                }
    
                setShowLoading(false);
            });
        }
        else {
            let payload = {
                BusinessSubscriptionEntityID: row.id,
                IsActive: row.rowData["IsActive"] === "true" || row.rowData["IsActive"] === true,
                ContextToken: contextToken
            }

            businessSubscriptionInstance.updateItem(payload).then((response) => {
                if(response.status !== 200){
                    setError(true);
                    setShowLoading(false);
                    return;
                }
    
                setShowLoading(false);
            });
        }
    }

    const deleteRows = async (guidList) => {
        
    }


    return (
        <div className="business-subscriptions-container">
            <div className="business-subscriptions-grid-container">
                <DataGrid gridRows={rows} gridHeaders={headers} saveGridChanges={saveChanges} getEmptyRow={getEmptyRow}
                    getCellValue={getGridRowCellValue} canCommitRow={canCommitRow} deleteRows={deleteRows} 
                    sortGridRows={sortGridRows} assignRowValues={assignRowValues} getDropdownCellOptions={getDropdownCellOptions}/>
            </div>
            {showLoading && <LoadingOverlay />}
            <ErrorDialog open={error} handleClose={setError} title="Error">
                An unexpected error occured. Please try again later.
            </ErrorDialog>
        </div>
    )
}

export default BusinessSubscriptions;