import { HTTP_VERB } from "../Enums/http-enums";
import HttpClient from "./HttpClient/http-client";
import { AxiosRequestConfig } from "axios";
import { API_HOST } from "../api-config";

const baseUrl = API_HOST + "/api/";

class ProductService {
    static instance: ProductService;
    client: typeof HttpClient;

    constructor(client: typeof HttpClient){
        if(!ProductService.instance){
            this.client = client;
            ProductService.instance = this;
            return ProductService.instance;
        }

        this.client = ProductService.instance.client;
        return ProductService.instance;
    }

    async getProducts(){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.GET,
            baseUrl: baseUrl,
            url: baseUrl + "Product/GetProducts",
            headers: {
                "Content-Type": "application/json"
            },
            data: null
        }

        return (await this.client.request(config));
    }
}

const productServiceInstance = new ProductService(HttpClient);
Object.freeze(productServiceInstance);

export default productServiceInstance;