
const BuildEnvHostNames = Object.freeze({
    Localhost: "localhost",
    Dev: "",
    QA: "",
    Production: "www.admin.trans-tech-solutions.co.za"
});

let apiHost;

const hostname = window && window.location && window.location.hostname;

if (hostname === BuildEnvHostNames.Dev){
    apiHost = "";
} else if (hostname === BuildEnvHostNames.QA){
    apiHost = "";
} else if (hostname === BuildEnvHostNames.Production || hostname === "admin.trans-tech-solutions.co.za"){
    apiHost = "https://api.trans-tech-solutions.co.za";
} else if (hostname === BuildEnvHostNames.Localhost){
    apiHost = "http://localhost:5000";
}

export const API_HOST = apiHost;