import "./Header.css";
import { Typography } from "@mui/material";
import { buttonVariants, buttonSizes } from "../../Shared/Components/Buttons/ButtonEnums";
import BasicButton from "../../Shared/Components/Buttons/BasicButton";
import { useNavigate } from 'react-router-dom';
import userContextServiceInstance from "../../Shared/Services/user-context-service";

const Header = (props) => {
    let navigate = useNavigate();
    const logo = window.location.origin + "/Images/Logos/logo_large.jpg";

    const loginClicked = () => {
        navigate("/login", { replace: true });
    }

    const titleClicked = () => {
        navigate("/");
    }

    const logoutClicked = () => {
        navigate("/");
        userContextServiceInstance.clearUserContext();
        props.setLogggedIn(false);
    }

    return (
        <div>
            <div className="header-container">
                <div className="title" onClick={titleClicked}>
                    <img src={logo} width="45" height="45"/>
                    <div className="logo-text">TRAN TECH SOLUTIONS ADMIN</div>
                </div>
                {/* <Typography className="title" variant="h5" fontWeight="bold" onClick={titleClicked}>Tran Tech Solutions</Typography> */}
                {!props.loggedIn 
                ?
                <div className="header-right-container">
                    <BasicButton variant={buttonVariants.contained} size={buttonSizes.medium} text="Login" 
                        onClick={loginClicked} color="primary"/>
                </div>
                :
                <div className="header-right-container">
                    <Typography className="title" variant="h6" onClick={() => navigate("/my-profile")}>{props.firstName} {props.lastName}</Typography>
                    <BasicButton variant={buttonVariants.outlined} size={buttonSizes.small} text="Logout" 
                        onClick={logoutClicked} color="primary"/>
                </div>   
                }
            </div>
        </div>
    )
}

export default Header;