import { useEffect, useState } from "react";
import DataGrid from "../../Shared/Components/DataGrid/DataGrid";
import ErrorDialog from "../../Shared/Components/Dialogs/ErrorDialog/ErrorDialog";
import LoadingOverlay from "../../Shared/Components/Overlays/LoadingOverlay/LoadingOverlay";
import "./Businesses.css"
import businessServiceInstance from "../../Shared/Services/business-service";
import userContextServiceInstance from "../../Shared/Services/user-context-service";
import { v4 as uuidv4 } from 'uuid';

const Businesses = () => {
    const [showLoading, setShowLoading] = useState(true);
    const [error, setError] = useState(false);
    const [rows, setRows] = useState([]);
    const [headers, setHeaders] = useState([]);

    useEffect(() => {
        const getData = async () => {
            const gridHeaders = [
                {id: 1, value: "OwnerEmail", displayValue: "Owner Email", cellWidth: 250, validCellValue: validOWnerEmail, isEditable: true}, 
                {id: 2, value: "Name", displayValue: "Business Name", cellWidth: 250, validCellValue: validName, isEditable: true},
                {id: 3, value: "Description", displayValue: "Business Description", cellWidth: 350, validCellValue: validDescription, isEditable: true},
                {id: 4, value: "IsActive", displayValue: "Is Active", cellWidth: 100, validCellValue: validIsAdmin, isEditable: true},
            ];

            setHeaders(gridHeaders);
            
            await getGridRowDataFromApi();
            setShowLoading(false);
        }

        getData().catch(err => {
            console.error(err);
            setShowLoading(false);
            setError(true);
        });
    }, []);

    const getGridRowDataFromApi = async () => {
        var response = await businessServiceInstance.getAll();
        if(response.status !== 200){
            setError(true);
            setShowLoading(false);
            return;
        }

        let items = response.data;

        let gridRows = [];
        items.forEach(item => {
            gridRows.push(
                {
                    id: item.businessEntityId, 
                    rowData: { OwnerEmail: item.ownerEmail, Name: item.name, Description: item.description, IsActive: item.isActive }, 
                    hasError: false,
                    rowSelected: false,
                    isEmpty: false,
                    hasChanges: false,
                    isComplete: true,
                    isNewRow: false
                }
            );
        })

        gridRows = sortGridRows(gridRows);
        setRows(gridRows);
    }

    const sortGridRows = (rows) => {
        rows.sort((a, b) => {
            let name1 = a.rowData["Name"];
            let name2 = b.rowData["Name"];
            if(name1 === "" || name2 === "") { return 1; }
            return name1.localeCompare(name2);
        });

        return rows;
    }

    const getGridRowCellValue = (headerValue, row) => {
        switch(headerValue){
            default:
                return row.rowData[headerValue];
        }
    }

    const assignRowValues = (row, header, newValue) => {
        row.rowData[header.value] = newValue;
        return row;
    }

    const getEmptyRow = () => {
        return { 
            id: uuidv4(), 
            rowData: {OwnerEmail: "", Name: "", Description: "", IsActive: ""}, 
            hasError: false, 
            rowSelected: false, 
            isEmpty: true,
            hasChanges: false,
            isComplete: true,
            isNewRow: true
        };
    }

    const canCommitRow = (row) => {
        return row.rowData["OwnerEmail"] !== "" && row.rowData["Name"] !== "" && row.rowData["Description"] !== "" && 
            row.rowData["IsActive"] !== "";
    }

    const validName = (value) => {
        return value !== null && value !== undefined;
    }

    const validOWnerEmail = (value) => {
        return value !== null && value !== undefined;
    }

    const validDescription = (value) => {
        return value !== null && value !== undefined;
    }

    const validIsAdmin = (value) => {
        return value !== null && value !== undefined && (value === "true" || value === "false"); 
    }

    const saveChanges = async (row) => {
        const contextToken = userContextServiceInstance.getContextToken();
        let payload = {
            EntityId: row.id,
            Name: row.rowData["Name"],
            Description: row.rowData["Description"],
            OwnerEmail: row.rowData["OwnerEmail"],
            IsActive: row.rowData["IsActive"] === "true" || row.rowData["IsActive"] === true,
            ContextToken: contextToken
        };

        setShowLoading(true);
        if(row.isNewRow){
            businessServiceInstance.addItemAdmin(payload).then((response) => {
                if(response.status !== 200){
                    setError(true);
                    setShowLoading(false);
                    return;
                }
    
                setShowLoading(false);
            });
        }
        else {
            businessServiceInstance.updateItem(payload).then((response) => {
                if(response.status !== 200){
                    setError(true);
                    setShowLoading(false);
                    return;
                }
    
                setShowLoading(false);
            });
        }
    }

    const deleteRows = async (guidList) => {
        var contextToken = userContextServiceInstance.getContextToken();
        var payload = {
            ContextToken: contextToken,
            BusinessEntityIDs: guidList
        }

        setShowLoading(true);
        var response = await businessServiceInstance.removeItems(payload);
        if(response.status !== 200){
            setError(true);
            setShowLoading(false);
            return;
        }

        setShowLoading(false);
        await getGridRowDataFromApi();
    }

    return (
        <div className="business-container">
            <div className="business-grid-container">
                <DataGrid gridRows={rows} gridHeaders={headers} saveGridChanges={saveChanges} getEmptyRow={getEmptyRow}
                    getCellValue={getGridRowCellValue} canCommitRow={canCommitRow} deleteRows={deleteRows} 
                    sortGridRows={sortGridRows} assignRowValues={assignRowValues}/>
            </div>
            {showLoading && <LoadingOverlay />}
            <ErrorDialog open={error} handleClose={setError} title="Error">
                An unexpected error occured. Please try again later.
            </ErrorDialog>
        </div>
    );
}

export default Businesses;