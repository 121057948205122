
class UserContextService {
    static instance: UserContextService;

    constructor(){
        if(!UserContextService.instance){
            UserContextService.instance = this;
            return UserContextService.instance;
        }

        return UserContextService.instance;
    }

    createUserContext(email: string, contextToken: string, accessToken: string, refreshToken: string){
        localStorage.setItem("email", email);
        localStorage.setItem("token", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("contextToken", contextToken);
    }

    setProductEntityID(value){
        return localStorage.setItem("productEntityID", value);
    }

    removeProductEntityID(){
        return localStorage.removeItem("productEntityID");
    }

    getProductEntityID(){
        return localStorage.getItem("productEntityID");
    }

    setProductName(value){
        return localStorage.setItem("productName", value);
    }

    getProductName(){
        return localStorage.getItem("productName");
    }

    removeProductName(){
        return localStorage.removeItem("productName");
    }

    getEmail(){
        return localStorage.getItem("email");
    }

    getAccessToken(){
        return localStorage.getItem("token");
    }

    getRefreshToken(){
        return localStorage.getItem("refreshToken");
    }

    getContextToken(){
        return localStorage.getItem("contextToken");
    }

    getUserContext(){
        return {
            Email: this.getEmail(),
            AccessToken: this.getAccessToken(),
            RefreshToken: this.getRefreshToken(),
            ContextToken: this.getContextToken(),
            ProductEntityID: this.getProductEntityID(),
            ProductName: this.getProductName()
        }
    }

    updateAuthorizationTokens(accessToken: string, refreshToken: string){
        localStorage.setItem("token", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
    }

    clearUserContext(){
        localStorage.removeItem("email");
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("contextToken");
        localStorage.removeItem("businessEntityID");
        localStorage.removeItem("productName");
        localStorage.removeItem("productEntityID");
    }
}

const userContextServiceInstance = new UserContextService();
Object.freeze(userContextServiceInstance);

export default userContextServiceInstance;