import { HTTP_VERB } from "../Enums/http-enums";
import HttpClient from "./HttpClient/http-client";
import { AxiosRequestConfig } from "axios";
import { API_HOST } from "../api-config";

const baseUrl = API_HOST + "/api/";

class SubscriptionService {
    static instance: SubscriptionService;
    client: typeof HttpClient;

    constructor(client: typeof HttpClient){
        if(!SubscriptionService.instance){
            this.client = client;
            SubscriptionService.instance = this;
            return SubscriptionService.instance;
        }

        this.client = SubscriptionService.instance.client;
        return SubscriptionService.instance;
    }

    async getAllSubscriptions(productEntityId: guid){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.GET,
            baseUrl: baseUrl,
            url: baseUrl + "Subscriptions/GetAllSubscriptions/" + productEntityId,
            headers: {
                "Content-Type": "application/json"
            },
            data: null
        }

        return (await this.client.request(config));
    }

    async addItem(data: Object){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.POST,
            baseUrl: baseUrl,
            url: baseUrl + "Subscriptions/AddItem",
            headers: {
                "Content-Type": "application/json"
            },
            data: data
        }

        return (await this.client.request(config));
    }

    async updateItem(data: Object){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.PUT,
            baseUrl: baseUrl,
            url: baseUrl + "Subscriptions/UpdateItem",
            headers: {
                "Content-Type": "application/json"
            },
            data: data
        }

        return (await this.client.request(config));
    }

    async removeItems(data: Object){
        const config: AxiosRequestConfig = {
            method: HTTP_VERB.DELETE,
            baseUrl: baseUrl,
            url: baseUrl + "Subscriptions/RemoveItems",
            headers: {
                "Content-Type": "application/json"
            },
            data: data
        }

        return (await this.client.request(config));
    }
}

const subscriptionServiceInstance = new SubscriptionService(HttpClient);
Object.freeze(subscriptionServiceInstance);

export default subscriptionServiceInstance;