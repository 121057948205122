import { useEffect, useState } from "react";
import "./Users.css"
import userServiceInstance from "../../Shared/Services/user-service";
import ErrorDialog from "../../Shared/Components/Dialogs/ErrorDialog/ErrorDialog";
import LoadingOverlay from "../../Shared/Components/Overlays/LoadingOverlay/LoadingOverlay";
import { v4 as uuidv4 } from 'uuid';
import DataGrid from "../../Shared/Components/DataGrid/DataGrid";
import userContextServiceInstance from "../../Shared/Services/user-context-service";
import BasicButton from "../../Shared/Components/Buttons/BasicButton";
import { buttonSizes, buttonVariants } from "../../Shared/Components/Buttons/ButtonEnums";
import BasicTextField from "../../Shared/Components/TextFields/BasicTextField";
import { textFieldSizes, textFieldVariants } from "../../Shared/Components/TextFields/TextFieldEnums";

const Users = () => {
    const [showLoading, setShowLoading] = useState(true);
    const [error, setError] = useState(false);
    const [rows, setRows] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [generatedPassword, setGeneratedPassword] = useState("");

    useEffect(() => {
        const getUsersData = async () => {
            const gridHeaders = [
                {id: 1, value: "Name", displayValue: "Name", cellWidth: 200, validCellValue: validName, isEditable: true}, 
                {id: 2, value: "Surname", displayValue: "Surname", cellWidth: 200, validCellValue: validSurname, isEditable: true},
                {id: 3, value: "Cellphone", displayValue: "Cellphone", cellWidth: 150, validCellValue: validCellphone, isEditable: true},
                {id: 4, value: "IDNumber", displayValue: "ID Number", cellWidth: 150, validCellValue: validIDNumber, isEditable: true},
                {id: 5, value: "Email", displayValue: "Email", cellWidth: 250, validCellValue: validEmail, isEditable: true},
                {id: 6, value: "Password", displayValue: "Password", cellWidth: 200, validCellValue: validPassword, isEditable: true},
                {id: 7, value: "IsAdmin", displayValue: "Is Admin", cellWidth: 100, validCellValue: validIsAdmin, isEditable: true},
                {id: 8, value: "IsActive", displayValue: "Is Active", cellWidth: 100, validCellValue: validIsAdmin, isEditable: true},
                {id: 9, value: "Address", displayValue: "Address", cellWidth: 400, validCellValue: validAddress, isEditable: true},
            ];

            setHeaders(gridHeaders);
            
            await getGridRowDataFromApi();
            setShowLoading(false);
        }

        getUsersData().catch(err => {
            console.error(err);
            setShowLoading(false);
            setError(true);
        });
    }, []);

    const getGridRowDataFromApi = async () => {
        var response = await userServiceInstance.GetAllUsers();
        if(response.status !== 200){
            setError(true);
            setShowLoading(false);
            return;
        }

        let items = response.data;

        let gridRows = [];
        items.forEach(item => {
            gridRows.push(
                {
                    id: item.clientID, 
                    rowData: { Name: item.name, Surname: item.surname, Cellphone: item.cellphone, Email: item.email, Address: item.address, IDNumber: item.idNumber, Password: item.password, IsAdmin: item.isAdmin, IsActive: item.isActive }, 
                    hasError: false,
                    rowSelected: false,
                    isEmpty: false,
                    hasChanges: false,
                    isComplete: true,
                    isNewRow: false
                }
            );
        })

        gridRows = sortGridRows(gridRows);
        setRows(gridRows);
    }

    const sortGridRows = (rows) => {
        rows.sort((a, b) => {
            let surname1 = a.rowData["Surname"];
            let surname2 = b.rowData["Surname"];
            if(surname1 === "" || surname2 === "") { return 1; }
            return surname1.localeCompare(surname2);
        });

        return rows;
    }

    const getGridRowCellValue = (headerValue, row) => {
        switch(headerValue){
            case "Password":
                return row.rowData["Password"] === 'placeholder' ? "############" : row.rowData[headerValue];
            default:
                return row.rowData[headerValue];
        }
    }

    const assignRowValues = (row, header, newValue) => {
        row.rowData[header.value] = newValue;
        return row;
    }

    const getEmptyRow = () => {
        return { 
            id: uuidv4(), 
            rowData: {Name: "", Surname: "", Email: "", Address: "", IDNumber: "", Password: "", IsAdmin: "", Cellphone: "", IsActive: ""}, 
            hasError: false, 
            rowSelected: false, 
            isEmpty: true,
            hasChanges: false,
            isComplete: true,
            isNewRow: true
        };
    }

    const canCommitRow = (row) => {
        return row.rowData["Name"] !== "" && row.rowData["Surname"] !== "" && row.rowData["Email"] !== "" && 
            row.rowData["Address"] !== "" && row.rowData["IDNumber"] !== "" && row.rowData["Password"] !== "" 
            && row.rowData["IsAdmin"] !== "" && row.rowData["Cellphone"] !== "" && row.rowData["IsActive"] !== "";
    }
    
    const validName = (value) => {
        return value !== null && value !== undefined;
    }

    const validCellphone = (value) => {
        return value !== null && value !== undefined;
    }

    const validPassword = (value) => {
        return value !== null && value !== undefined;
    }

    const validIsAdmin = (value) => {
        return value !== null && value !== undefined && (value === "true" || value === "false"); 
    }

    const validSurname = (value) => {
        return value !== null && value !== undefined;
    }

    const validIDNumber = (value) => {
        return value !== null && value !== undefined;
    }

    const validEmail = (value) => {
        return value !== null && value !== undefined;
    }

    const validAddress = (value) => {
        return value !== null && value !== undefined;
    }

    const saveChanges = async (row) => {
        console.log(row.rowData);
        const contextToken = userContextServiceInstance.getContextToken();
        let payload = {
            EntityId: row.id,
            FirstName: row.rowData["Name"],
            LastName: row.rowData["Surname"],
            IDNumber: row.rowData["IDNumber"],
            Email: row.rowData["Email"],
            Cellphone: row.rowData["Cellphone"],
            Address: row.rowData["Address"],
            Password: row.rowData["Password"],
            IsAdmin: row.rowData["IsAdmin"] === "true" || row.rowData["IsAdmin"] === true,
            IsActive: row.rowData["IsActive"] === "true",
            ContextToken: contextToken
        };

        setShowLoading(true);
        if(row.isNewRow){
            userServiceInstance.createUserAdmin(payload).then((response) => {
                if(response.status !== 200){
                    setError(true);
                    setShowLoading(false);
                    return;
                }
    
                setShowLoading(false);
            });
        }
        else {
            userServiceInstance.UpdateUserAdmin(payload).then((response) => {
                if(response.status !== 200){
                    setError(true);
                    setShowLoading(false);
                    return;
                }
    
                setShowLoading(false);
            });
        }
    }

    const deleteRows = async (guidList) => {
        // const businessEntityId = userContextServiceInstance.getBusinessEntityID();
        // let payload = {
        //     BusinessEntityID: businessEntityId,
        //     IncomeEntityIDs: guidList
        // };

        // setShowLoading(true);
        // var response = await saleServiceInstance.removeItems(payload);
        // if(response.status !== 200){
        //     setError(true);
        //     setShowLoading(false);
        //     return;
        // }

        // setShowLoading(false);
        // await getGridRowDataFromApi();
    }

    const generatePasswordClicked = () => {
        var length = 10,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }

        setGeneratedPassword(retVal);
    }

    const getGridRowFilters = () => {

        return (
            <div className="password-generator-container">
                <BasicButton variant={buttonVariants.contained} size={buttonSizes.small} text="Generate Password" 
                        onClick={generatePasswordClicked} color="primary"/>
                <BasicButton variant={buttonVariants.outlined} size={buttonSizes.small} text="clear" 
                    onClick={() => setGeneratedPassword("")} color="primary"/>
                <BasicTextField id="password" variant={textFieldVariants.outlined} label="password" size={textFieldSizes.small} value={generatedPassword} 
                        onChange={(event) => { setGeneratedPassword(event.target.value) }}/>
            </div>
        );
    }

    return (
        <div className="users-container">
            <div className="user-grid-container">
                <DataGrid gridRows={rows} gridHeaders={headers} saveGridChanges={saveChanges} getEmptyRow={getEmptyRow}
                    getCellValue={getGridRowCellValue} canCommitRow={canCommitRow} deleteRows={deleteRows} 
                    sortGridRows={sortGridRows} assignRowValues={assignRowValues} getGridFilters={getGridRowFilters}/>
            </div>
            {showLoading && <LoadingOverlay />}
            <ErrorDialog open={error} handleClose={setError} title="Error">
                An unexpected error occured. Please try again later.
            </ErrorDialog>
        </div>
    );
}

export default Users;