import { useEffect, useState } from "react";
import "./Subscriptions.css"
import { v4 as uuidv4 } from 'uuid';
import subscriptionServiceInstance from "../../Shared/Services/subscription-service";
import { validateIntegerNumber, validDecimalNumber } from "../../Shared/Utils/number-utils";
import userContextServiceInstance from "../../Shared/Services/user-context-service";
import ErrorDialog from "../../Shared/Components/Dialogs/ErrorDialog/ErrorDialog";
import LoadingOverlay from "../../Shared/Components/Overlays/LoadingOverlay/LoadingOverlay";
import DataGrid from "../../Shared/Components/DataGrid/DataGrid";

const Subscriptions = () => {
    const [showLoading, setShowLoading] = useState(true);
    const [error, setError] = useState(false);
    const [rows, setRows] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [deleteError, setDeleteError] = useState(false);
    const [customDeleteError, setCustomDeleteError] = useState('');
    
    useEffect(() => {
        const getData = async () => {
            const gridHeaders = [
                {id: 1, value: "Description", displayValue: "Description", cellWidth: 250, validCellValue: validDescription, isEditable: true}, 
                {id: 2, value: "MonthlyDuration", displayValue: "Monthly Duration", cellWidth: 150, validCellValue: validDuration, isEditable: true},
                {id: 3, value: "Price", displayValue: "Price", cellWidth: 100, validCellValue: validPrice, isEditable: true},
                {id: 4, value: "IsActive", displayValue: "Is Active", cellWidth: 100, validCellValue: validIsActive, isEditable: true},
            ];

            setHeaders(gridHeaders);
            
            await getGridRowDataFromApi();
            setShowLoading(false);
        }

        getData().catch(err => {
            console.error(err);
            setShowLoading(false);
            setError(true);
        });
    }, []);

    const getGridRowDataFromApi = async () => {
        var productEntityId = userContextServiceInstance.getProductEntityID();
        var response = await subscriptionServiceInstance.getAllSubscriptions(productEntityId);
        if(response.status !== 200){
            setError(true);
            setShowLoading(false);
            return;
        }

        let items = response.data;

        let gridRows = [];
        items.forEach(item => {
            gridRows.push(
                {
                    id: item.entityID, 
                    rowData: { Description: item.description, MonthlyDuration: item.monthlyDuration, Price: item.price, IsActive: item.isActive }, 
                    hasError: false,
                    rowSelected: false,
                    isEmpty: false,
                    hasChanges: false,
                    isComplete: true,
                    isNewRow: false
                }
            );
        })

        gridRows = sortGridRows(gridRows);
        setRows(gridRows);
    }

    const sortGridRows = (rows) => {
        rows.sort((a, b) => {
            let name1 = a.rowData["Description"];
            let name2 = b.rowData["Description"];
            if(name1 === "" || name2 === "") { return 1; }
            return name1.localeCompare(name2);
        });

        return rows;
    }

    const getGridRowCellValue = (headerValue, row) => {
        switch(headerValue){
            default:
                return row.rowData[headerValue];
        }
    }

    const assignRowValues = (row, header, newValue) => {
        row.rowData[header.value] = newValue;
        return row;
    }

    const getEmptyRow = () => {
        return { 
            id: uuidv4(), 
            rowData: {Description: "", MonthlyDuration: "", Price: "", IsActive: ""}, 
            hasError: false, 
            rowSelected: false, 
            isEmpty: true,
            hasChanges: false,
            isComplete: true,
            isNewRow: true
        };
    }

    const canCommitRow = (row) => {
        return row.rowData["Description"] !== "" && row.rowData["MonthlyDuration"] !== "" && row.rowData["Price"] !== "" && 
            row.rowData["IsActive"] !== "";
    }
    
    const validDescription = (value) => {
        return value !== null && value !== undefined;
    }

    const validDuration = (value) => {
        if(value === ""){
            return true;
        }

        return validateIntegerNumber(value);
    }

    const validPrice = (value) => {
        if(value === ""){
            return true;
        }

        return validDecimalNumber(value);
    }

    const validIsActive = (value) => {
        return value !== null && value !== undefined && (value === "true" || value === "false"); 
    }

    const saveChanges = async (row) => {
        const contextToken = userContextServiceInstance.getContextToken();
        const productId = userContextServiceInstance.getProductEntityID();

        let payload = {
            EntityId: row.id,
            ProductEntityId: productId, 
            MonthlyDuration: row.rowData["MonthlyDuration"],
            Description: row.rowData["Description"],
            Price: row.rowData["Price"],
            IsActive: row.rowData["IsActive"] === "true" || row.rowData["IsActive"] === true,
            ContextToken: contextToken
        };

        setShowLoading(true);
        if(row.isNewRow){
            subscriptionServiceInstance.addItem(payload).then((response) => {
                if(response.status !== 200){
                    setError(true);
                    setShowLoading(false);
                    return;
                }
    
                setShowLoading(false);
            });
        }
        else {
            subscriptionServiceInstance.updateItem(payload).then((response) => {
                if(response.status !== 200){
                    setError(true);
                    setShowLoading(false);
                    return;
                }
    
                setShowLoading(false);
            });
        }
    }

    const deleteRows = async (guidList) => {
        var contextToken = userContextServiceInstance.getContextToken();
        var payload = {
            ContextToken: contextToken,
            SubscriptionEntityIDs: guidList
        }

        setShowLoading(true);
        var response = await subscriptionServiceInstance.removeItems(payload);
        if(response.status !== 200){
            setError(true);
            setShowLoading(false);
            return;
        }

        const data = response.data;
        if(!data.deleteSuccess && data.validationErrors){
            setDeleteError(true);
            setCustomDeleteError(data.validationErrors[0]);
        }

        setShowLoading(false);
        await getGridRowDataFromApi();
    }

    return (
        <div className="subscriptions-container">
            <div className="subscriptions-grid-container">
                <DataGrid gridRows={rows} gridHeaders={headers} saveGridChanges={saveChanges} getEmptyRow={getEmptyRow}
                    getCellValue={getGridRowCellValue} canCommitRow={canCommitRow} deleteRows={deleteRows} 
                    sortGridRows={sortGridRows} assignRowValues={assignRowValues}/>
            </div>
            {showLoading && <LoadingOverlay />}
            <ErrorDialog open={error} handleClose={setError} title="Error">
                An unexpected error occured. Please try again later.
            </ErrorDialog>
            <ErrorDialog open={deleteError} handleClose={setDeleteError} title="Error">
                {customDeleteError}
            </ErrorDialog>
        </div>
    );
}

export default Subscriptions;