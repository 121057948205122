import './App.css';
import { Routes, Route } from "react-router-dom";
import Login from './Components/Login/Login';
import Header from "./Components/Header/Header";
import { useEffect, useState } from 'react';
import ResetPassword from './Components/ResetPassword/ResetPassword';
import MyProfile from './Components/MyProfile/MyProfile';
import UserService from './Shared/Services/user-service';
import userContextServiceInstance from './Shared/Services/user-context-service';
import Products from "./Components/Products/Products";
import ProductManager from './Components/ProductManager/ProductManager';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  useEffect(() => {
    const unloadCallback = (event: any) => {
      const contextToken = userContextServiceInstance.getContextToken();
      UserService.logout({ContextToken: contextToken});

      userContextServiceInstance.clearUserContext();
    };
  
    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  return (
    <div className='app-container'>
      <Header loggedIn={loggedIn} setLogggedIn={setLoggedIn} firstName={firstName} lastName={lastName}/>
      <div className='app-routes'>
        <Routes>
          <Route path="/" element={<Login setLogggedIn={setLoggedIn} setFirstName={setFirstName} setLastName={setLastName}/>} />
          <Route path="/login" element={<Login setLogggedIn={setLoggedIn} setFirstName={setFirstName} setLastName={setLastName}/>}/>
          <Route path="/reset-password" element={<ResetPassword />}/>
          {loggedIn && <Route path="/my-profile" element={<MyProfile />} />}
          {loggedIn && <Route path="/products" element={<Products />} />}
          {loggedIn && <Route path="/product-manager" element={<ProductManager />} />}
        </Routes>
      </div>
    </div>
  );
}

export default App;
