import BasicTextField from "../../Shared/Components/TextFields/BasicTextField";
import PasswordField from "../../Shared/Components/TextFields/PasswordField";
import { buttonVariants, buttonSizes } from "../../Shared/Components/Buttons/ButtonEnums";
import { textFieldVariants, textFieldSizes } from "../../Shared/Components/TextFields/TextFieldEnums";
import { useState } from "react";
import BasicButton from "../../Shared/Components/Buttons/BasicButton";
import "./Login.css";
import { Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import UserService from "../../Shared/Services/user-service";
import LoadingOverlay from "../../Shared/Components/Overlays/LoadingOverlay/LoadingOverlay";
import ErrorDialog from "../../Shared/Components/Dialogs/ErrorDialog/ErrorDialog";
import EmailOTP from "../EmailOTP/EmailOTP";
import Recaptcha from "../../Shared/Components/Recaptcha/Recaptcha";
import UIProvider from "../../Shared/Components/Providers/UIProvider/UIProvider";
import userContextServiceInstance from "../../Shared/Services/user-context-service";
import { useMediaQuery } from "react-responsive";

const Login = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showLoading, setShowloading] = useState(false);
    const [error, setError] = useState(false);
    const [invalidUser, setInvalidUser] = useState(false);
    const [unauthorizedUser, setUnauthorizedUser] = useState(false);
    const [showOtpScreen, setShowOtpScreen] = useState(false);
    const [loginContext, setLoginContext] = useState({});
    const [recaptchaPassed, setRecaptchaPassed] = useState(true); // this is true for recaptcha
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const containerStyleClasses = isMobile ? "login-container-small" : "login-container-large";

    const loginClicked = () => {
        setShowloading(true);
        var payload = { Username: email, Password: password, IsAdmin: true };
        UserService.login(payload).then(response => {
            if(!response){
                setError(true);
                setShowloading(false);
                return;
            }

            if(response.status === 404){
                setInvalidUser(true);
                setShowloading(false);
                return;
            }
            else if(response.status === 401){
                setUnauthorizedUser(true);
                setShowloading(false);
                return;
            }
            else if(response.status !== 200){
                setError(true);
                setShowloading(false);
                return;
            }

            setShowloading(false);
            setLoginContext(response.data);
            setShowOtpScreen(true); // Comment this out if you want to disable otps
            // uncommented below if no email is to be sent
            // setItemsInUserContext(response.data);
            // props.setFirstName(response.data.firstName);
            // props.setLastName(response.data.lastName);
            // props.setLogggedIn(true);
            // navigate("/products");
        })
        
    }

    const setItemsInUserContext = (loginData = null) => {
        if(loginData){
            userContextServiceInstance.createUserContext(loginData.email, loginData.contextToken, loginData.token, loginData.refreshToken);
        }else{
            userContextServiceInstance.createUserContext(loginContext.email, loginContext.contextToken, loginContext.token, loginContext.refreshToken);
        }
    }

    const submitOTPSuccess = () => {
        setItemsInUserContext();
        props.setFirstName(loginContext.firstName);
        props.setLastName(loginContext.lastName);
        props.setLogggedIn(true);
        navigate("/products");
    }

    const resetPasswordClicked = () => {
        navigate("/reset-password");
    }

    const submitOtpFailure = () => {
        setShowOtpScreen(false);
        setError(true);
    }

    const HandleRecaptchaResult = (value) => {
        setRecaptchaPassed(value);
    }

    return (
        <div className={containerStyleClasses}>
            <UIProvider>
                {showOtpScreen ? 
                    <EmailOTP email={email} submitOTPSuccess={submitOTPSuccess} submitOtpFailure={submitOtpFailure}/>
                :
                <>
                    <Typography variant="h4" align="center" fontStyle="italic" fontWeight="bold">Login</Typography>
                    <BasicTextField id="email" variant={textFieldVariants.standard} label="Email" size={textFieldSizes.medium} value={email} 
                        onChange={(event) => { setEmail(event.target.value) }}/>
                    <PasswordField id="password" variant={textFieldVariants.standard} label="Password" size={textFieldSizes.medium} value={password} 
                        onChange={(event) => { setPassword(event.target.value) }} fullWidth={true}/>
                    {/* <Recaptcha setRecaptchaResult={HandleRecaptchaResult}/> */}
                    <BasicButton variant={buttonVariants.contained} size={buttonSizes.medium} text="Login" 
                        onClick={loginClicked} color="primary" disabled={!recaptchaPassed}/>
                    <BasicButton variant={buttonVariants.outlined} size={buttonSizes.small} text="Reset password" 
                        onClick={resetPasswordClicked} color="primary"/>
                </>
                }
            </UIProvider>
            {showLoading && <LoadingOverlay />}
            <ErrorDialog open={error} handleClose={setError} title="Error">
                An unexpected error occured. Please try again later.
            </ErrorDialog>
            <ErrorDialog open={invalidUser} handleClose={setInvalidUser} title="Error">
                Invalid user credentials provided!
            </ErrorDialog>
            <ErrorDialog open={unauthorizedUser} handleClose={setUnauthorizedUser} title="Error">
                You do not have permissions to access this site. Please contact your admin to request access.
            </ErrorDialog>
        </div>
    );
}

export default Login;